<template>
  <div class="blog__card-root">
    <div class="blog__card-img adaptive-img">
      <div class="blog__card-tags">
        <span
          v-for="tag in news.tags"
          :key="`tag-card-news-${tag.title}`"
          class="blog__card-tag"
          :class="{'--small': smallTag}"
        >{{ tag.title }}</span>
      </div>
      <nuxt-img
        width="368"
        height="221"
        loading="lazy"
        placeholder
        :src="String(news.imageId)"
        fit="contain"
      />
    </div>
    <div class="blog__card-content">
      <div class="blog__card-title">{{ news.title }}</div>
      <div class="blog__card-announce">{{ announce }}</div>
      <div class="blog__card-bottom">
        <div class="blog__card-readmore">
          <NuxtLink :to="news.url">Подробнее</NuxtLink>
          <div class="blog__card-date">
            {{ news.date }}
          </div>
        </div>
      </div>
    </div>
    <NuxtLink
      class="link-absolute"
      :to="news.url"
    />
  </div>
</template>

<script setup lang="ts">
import type { IArticleItem } from '#sitis/internal/controllers/articles/models/ArticleItem';

const { news } = defineProps<{
	news: IArticleItem;
  smallTag?: boolean;
}>();

const announce = computed(() => {
  if (!news.announce) {
    return '';
  }
  let sliced = news.announce.slice(0, 145);

  if (sliced.length < news.announce.length) {
    sliced += '...';
    return sliced;
  }
  return news.announce;
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.blog__card-root {
  width: 100%;
	padding: 12px 12px 21px;
	border-radius: 14px;
	border: 1px solid rgb(226 221 233 / 50%);
	background: var(--white);
	box-shadow: 0 0 0 0 rgb(0 0 0 / 3%), 0 2px 4px 0 rgb(0 0 0 / 3%), 0 7px 7px 0 rgb(0 0 0 / 3%), 0 16px 9px 0 rgb(0 0 0 / 2%), 0 28px 11px 0 rgb(0 0 0 / 0%), 0 44px 12px 0 rgb(0 0 0 / 0%);
	display: flex;
	flex-direction: column;
	position: relative;
	transition: all .3s linear;

	&:hover {
		box-shadow: 0 1px 10px 0 rgb(4 78 146 / 12%);
	}
}

.blog__card-tags {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	width: 100%;
	position: absolute;
	z-index: 2;
	top: 10px;
	left: 10px;
}

.blog__card-tag {
	border-radius: 15px;
	background: var(--secondary);
	padding: 5px 12px;
	color: var(--primary);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

  &.--small {
    font-size: 12px;
    font-weight: 600;
  }
}

.blog__card-img {
	position: relative;
	margin-bottom: 24px;
	height: 220px;

	&::before {
		padding-top: 50%;
	}

	& > img {
		border-radius: 10px;
		position: absolute;
		width: 100%;
		height: 100%;
		inset: 0;
		object-fit: cover;
	}
}

.blog__card-title {
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
	color: var(--primary);
	margin-bottom: 24px;
}

.blog__card-announce {
	color: var(--primary-80);
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 25.2px */
	letter-spacing: -0.36px;
	margin-bottom: 22px;
}

.blog__card-date {
	font-size: 14px;
	font-weight: 400;
	color: var(--primary-40);
}

.blog__card-readmore {
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > a {
		background-color: var(--secondary);
		padding: 4px 20px;
		color: var(--primary);
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 24.825px; /* 155.153% */
		letter-spacing: -0.32px;
		border-radius: 62px;
		transition: opacity .3s linear;
		box-shadow: 0 0 0 0 rgb(0 0 0 / 4%), 0 2.4px 4.9649px 0 rgb(0 0 0 / 4%), 0 8.6px 8.6px 0 rgb(0 0 0 / 4%), 0 19.8px 11.1px 0 rgb(0 0 0 / 3%), 0 34.7px 13.6px 0 rgb(0 0 0 / 1%), 0 54.6px 14.8px 0 rgb(0 0 0 / 0%);
		cursor: pointer;

		&:hover {
			opacity: .7;
		}
	}
}

.blog__card-bottom {
	margin-top: auto;
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: relative;
	z-index: 5;
}

.blog__card-content {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 10px;
}

@include media('sm') {
	.blog__card-content{
		padding: 0 6px;
	}

	.blog__card-tag {
		font-size: 14px;
	}

	.blog__card-title {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 130%; /* 20.8px */
		letter-spacing: -0.32px;
		color: var(--primary);
		margin-bottom: 15px;
	}

	.blog__card-readmore > button {
		font-size: 10px;
	}

	.blog__card-date {
		font-size: 12px;
	}

	.blog__card-img {
		height: 180px;
		margin-bottom: 20px;
	}

	.blog__card-announce {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 130%; /* 18.2px */
		letter-spacing: -0.28px;
	}

}

</style>
